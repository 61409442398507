"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpertListPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var useTableFilters_1 = require("../../../../../hooks/useTableFilters");
var routes_1 = require("../../../../../navigation/routes");
var filter_values_1 = require("../../../../ud-ui/components/filter-values");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var constants_2 = require("../../../domain/constants");
var getExpertsColumns_1 = require("../../../domain/helpers/getExpertsColumns");
var expertsApi_1 = require("../../../store/api/expertsApi");
var ExpertListPage = function () {
    var history = (0, react_router_dom_1.useHistory)();
    var _a = (0, useTableFilters_1.useTableFilters)(constants_1.LOCATION_KEYS.expertList, constants_2.expertsTableFiltersValues), tableFilters = _a.tableFilters, pagination = _a.pagination, changeTableFilter = _a.changeTableFilter, deleteTableFilter = _a.deleteTableFilter, getTableFiltersParams = _a.getTableFiltersParams, setTableFilter = _a.setTableFilter, setPageParams = _a.setPageParams;
    var experts = (0, expertsApi_1.useFetchExpertsQuery)((0, helpers_1.getQueryParams)(__assign(__assign({}, getTableFiltersParams()), (0, helpers_1.getPaginationParams)(pagination))));
    var handleDoubleClick = (0, react_1.useCallback)(function (_a) {
        var row = _a.row;
        history.push((0, react_router_dom_1.generatePath)(routes_1.routes.expertContractor, { id: row.id }));
    }, [routes_1.routes, pagination, tableFilters]);
    var dataGridProps = react_1.default.useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            columns: (0, getExpertsColumns_1.getExpertsColumns)({
                onSubmit: setTableFilter,
            }),
            rows: (_b = (_a = experts.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [],
            rowCount: (_d = (_c = experts.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0,
        });
    }, [experts.data]);
    return ((0, jsx_runtime_1.jsxs)(page_container_1.PageContainer, __assign({ subTitle: "\u042D\u043A\u0441\u043F\u0435\u0440\u0442\u044B" }, { children: [(0, jsx_runtime_1.jsx)(filter_values_1.FilterValues, { values: tableFilters, onDelete: deleteTableFilter, onChange: changeTableFilter }), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, dataGridProps, pagination, { isLoading: experts.isFetching, onPageModeChange: setPageParams, onCellDoubleClick: handleDoubleClick }))] })));
};
exports.ExpertListPage = ExpertListPage;
