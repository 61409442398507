"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateUserPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var constants_1 = require("../../../../../constants");
var useSnackbar_1 = require("../../../../../hooks/useSnackbar");
var routes_1 = require("../../../../../navigation/routes");
var go_back_container_1 = require("../../../../ud-ui/components/go-back-container");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var usersApi_1 = require("../../../store/api/usersApi");
var user_form_1 = require("../../components/user-form");
var CreateUserPage = function (_a) {
    var user = _a.user;
    var _b = (0, usersApi_1.useCreateUserMutation)(), createUser = _b[0], results = _b[1];
    (0, useSnackbar_1.useSuccessAlert)(results, constants_1.alertMessages.addUserSuccess, routes_1.routes.users);
    var handleUserSubmit = (0, react_1.useCallback)(function (value) {
        createUser(value);
    }, []);
    return ((0, jsx_runtime_1.jsx)(page_container_1.PageContainer, __assign({ subTitle: "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438 (\u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435)", leftElement: (0, jsx_runtime_1.jsx)(go_back_container_1.GoBackContainer, { goBackPath: routes_1.routes.users }) }, { children: (0, jsx_runtime_1.jsx)(user_form_1.UserForm, { isDisabled: results.isLoading, user: user, onSubmit: handleUserSubmit }) })));
};
exports.CreateUserPage = CreateUserPage;
