"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchContractorsOnSubQuery = exports.contractorsOnSubApi = void 0;
var react_1 = require("@reduxjs/toolkit/dist/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
exports.contractorsOnSubApi = (0, react_1.createApi)({
    reducerPath: 'contractorsOnSub',
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ['ContractorsOnSub'],
    endpoints: function (builder) { return ({
        fetchContractorsOnSub: builder.query({
            query: function (params) { return ({
                url: "/api/v1/contractoronsubscription".concat(params),
            }); },
            providesTags: ['ContractorsOnSub'],
            transformResponse: function (result) { return ({
                rows: result.payload,
                total: result.meta.total,
            }); },
        }),
    }); },
});
exports.useFetchContractorsOnSubQuery = exports.contractorsOnSubApi.useFetchContractorsOnSubQuery;
