"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPermissionInStorage = exports.hasPermission = void 0;
var responsibilities_1 = require("../../../api/responsibilities");
var hasPermission = function (requestUrl, methodUrl) {
    var _a, _b;
    var permissions = (_a = localStorage.getItem('permissions')) === null || _a === void 0 ? void 0 : _a.split(',');
    var nameInPermission = (_b = responsibilities_1.responsibilities.find(function (_a) {
        var url = _a.url, method = _a.method;
        return url.find(function (i) { return i === requestUrl; }) && method.find(function (i) { return i == methodUrl; });
    })) === null || _b === void 0 ? void 0 : _b.responsibility;
    return !!(permissions === null || permissions === void 0 ? void 0 : permissions.find(function (name) { return name === nameInPermission; }));
};
exports.hasPermission = hasPermission;
var hasPermissionInStorage = function (permission) {
    var permissions = localStorage.getItem('permissions');
    return permissions === null || permissions === void 0 ? void 0 : permissions.includes(permission);
};
exports.hasPermissionInStorage = hasPermissionInStorage;
