"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractorExpertsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var routes_1 = require("../../../../../navigation/routes");
var icon_info_1 = require("../../../../ud-ui/components/icon-info");
var table_container_1 = require("../../../../ud-ui/components/table-container");
var ContractorExpertsPage = function (_a) {
    var _b;
    var contractor = _a.contractor;
    var history = (0, react_router_dom_1.useHistory)();
    var handleDoubleClick = (0, react_1.useCallback)(function (id) { return function () {
        history.push((0, react_router_dom_1.generatePath)(routes_1.routes.expertContractor, { id: id }));
    }; }, [routes_1.routes]);
    return ((0, jsx_runtime_1.jsx)(table_container_1.TableContainer, { children: (0, jsx_runtime_1.jsxs)(material_1.Table, { children: [(0, jsx_runtime_1.jsx)(material_1.TableHead, { children: (0, jsx_runtime_1.jsx)(material_1.TableRow, { children: (0, jsx_runtime_1.jsx)(material_1.TableCell, { children: "\u0424\u0418\u041E" }) }) }), (0, jsx_runtime_1.jsx)(material_1.TableBody, { children: ((_b = contractor === null || contractor === void 0 ? void 0 : contractor.experts) === null || _b === void 0 ? void 0 : _b.length) ? (contractor.experts.map(function (expert) { return ((0, jsx_runtime_1.jsx)(material_1.TableRow, __assign({ sx: { cursor: 'pointer' } }, { children: (0, jsx_runtime_1.jsxs)(material_1.TableCell, __assign({ onDoubleClick: handleDoubleClick(expert.id) }, { children: [expert.name, " ", expert.isBlocked && (0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { type: constants_1.IconInfoType.BlockedExpert })] })) }), expert.id)); })) : ((0, jsx_runtime_1.jsx)(material_1.TableRow, { children: (0, jsx_runtime_1.jsx)(material_1.TableCell, __assign({ sx: { padding: 5 }, align: "center" }, { children: "\u042D\u043A\u0441\u043F\u0435\u0440\u0442\u044B \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442" })) })) })] }) }));
};
exports.ContractorExpertsPage = ContractorExpertsPage;
