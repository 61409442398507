"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useContractorTypes = void 0;
var react_1 = require("react");
var constants_1 = require("../constants");
var helpers_1 = require("../helpers");
function useContractorTypes(locationKey) {
    var _a = (0, react_1.useState)((0, helpers_1.getSavedContractorTypes)(locationKey)), contractorTypes = _a[0], setContractorTypes = _a[1];
    var handelContractorTypesChange = function (e) {
        if (Array.isArray(e.target.value)) {
            setContractorTypes(e.target.value);
            (0, helpers_1.saveSessionData)(e.target.value, locationKey, constants_1.PERSISTENT_KEYS.contractorTypes);
        }
    };
    return {
        contractorTypes: contractorTypes,
        handelContractorTypesChange: handelContractorTypesChange,
    };
}
exports.useContractorTypes = useContractorTypes;
