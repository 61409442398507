"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApproverGroupStageIds = void 0;
var getApproverGroupStageIds = function (approverGroupId, processTypes) {
    var values = [];
    processTypes === null || processTypes === void 0 ? void 0 : processTypes.forEach(function (processType) {
        var _a;
        (_a = processType.stages) === null || _a === void 0 ? void 0 : _a.forEach(function (stage) {
            if (stage.approverGroup && stage.approverGroup.id === approverGroupId) {
                values.push(stage.id);
            }
        });
    });
    return values;
};
exports.getApproverGroupStageIds = getApproverGroupStageIds;
