"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileInfo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var icon_info_1 = require("../icon-info");
var S = __importStar(require("./style"));
var ProfileInfo = function (_a) {
    var className = _a.className, icons = _a.icons, name = _a.name, period = _a.period, values = _a.values;
    return ((0, jsx_runtime_1.jsxs)(S.Root, __assign({ className: className, "data-id": "profileInfo" }, { children: [(0, jsx_runtime_1.jsxs)(S.Title, { children: [(0, jsx_runtime_1.jsx)(S.Name, __assign({ "data-id": "nameProfileInfo" }, { children: name })), icons === null || icons === void 0 ? void 0 : icons.map(function (icon, i) { return (0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { type: icon }, i); })] }), values === null || values === void 0 ? void 0 : values.map(function (_a, i) {
                var label = _a.label, value = _a.value;
                return Array.isArray(value) ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(S.SubTitle, __assign({ "data-id": "labelProfileInfo" }, { children: [label, ":"] }), i), value.map(function (v) { return ((0, jsx_runtime_1.jsx)(S.SubTitle, __assign({ "data-id": "titleProfileInfo" }, { children: v }), i)); })] })) : ((0, jsx_runtime_1.jsxs)(S.SubTitle, __assign({ "data-id": "titleProfileInfo" }, { children: [label, ": ", value] }), i));
            }), (0, jsx_runtime_1.jsx)(S.PeriodContainer, { children: period && ((0, jsx_runtime_1.jsxs)(S.SubTitle, __assign({ isPeriod: true, "data-id": "periodProfileInfo" }, { children: ["\u041D\u0430 \u043F\u0435\u0440\u0438\u043E\u0434 ", period] }))) })] })));
};
exports.ProfileInfo = ProfileInfo;
