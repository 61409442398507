"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateMonitoringEventMutation = exports.useFetchMonitoringEventsQuery = exports.useFetchMonitoringEventQuery = exports.monitoringEventsApi = void 0;
var react_1 = require("@reduxjs/toolkit/dist/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
var get_formatted_value_1 = require("../../domain/helpers/get-formatted-value");
exports.monitoringEventsApi = (0, react_1.createApi)({
    reducerPath: 'monitoringEventsList',
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ['MonitoringEvents', 'MonitoringEvent'],
    endpoints: function (builder) { return ({
        fetchMonitoringEvents: builder.query({
            query: function (params) { return ({
                url: "/api/v1/monitoringevent".concat(params),
            }); },
            providesTags: ['MonitoringEvents'],
            transformResponse: function (result) { return ({
                rows: result.payload.map(function (values) { return (__assign(__assign({}, values), { value: __assign(__assign({}, values.value), { value: (0, get_formatted_value_1.getFormattedValue)(values.value) }) })); }),
                total: result.meta.total,
            }); },
        }),
        fetchMonitoringEvent: builder.query({
            query: function (id) { return ({
                url: "/api/v1/monitoringevent/".concat(id),
            }); },
            providesTags: ['MonitoringEvent'],
            transformResponse: function (result) { return result.payload; },
        }),
        updateMonitoringEvent: builder.mutation({
            query: function (_a) {
                var body = _a.body, id = _a.id;
                return ({
                    url: "/api/v1/monitoringevent/".concat(id),
                    method: 'PUT',
                    body: body,
                });
            },
            invalidatesTags: ['MonitoringEvents', 'MonitoringEvent'],
            transformResponse: function (result) { return result.payload; },
        }),
    }); },
});
exports.useFetchMonitoringEventQuery = exports.monitoringEventsApi.useFetchMonitoringEventQuery, exports.useFetchMonitoringEventsQuery = exports.monitoringEventsApi.useFetchMonitoringEventsQuery, exports.useUpdateMonitoringEventMutation = exports.monitoringEventsApi.useUpdateMonitoringEventMutation;
