"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessList = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var styles_1 = require("../../../../../styles");
var S = __importStar(require("./style"));
var ProcessList = function (props) {
    var selectedProcessId = props.selectedProcessId, values = props.values, onSelect = props.onSelect;
    var chooseHandle = (0, react_1.useCallback)(function (key) {
        onSelect(key);
    }, []);
    return ((0, jsx_runtime_1.jsx)(S.ListContainer, { children: (0, jsx_runtime_1.jsx)("div", __assign({ style: { overflow: 'auto', height: 800 } }, { children: (0, jsx_runtime_1.jsx)(material_1.List, { children: values.map(function (process) { return ((0, jsx_runtime_1.jsxs)(styles_1.ItemRow, { children: [(0, jsx_runtime_1.jsx)(S.ChooseHelperDiv, { status: process.status }), (0, jsx_runtime_1.jsxs)(S.ItemWithDivider, { children: [(0, jsx_runtime_1.jsxs)(S.Item, __assign({ status: process.status, onClick: function () { return chooseHandle(process); }, active: process.id === selectedProcessId }, { children: [(0, jsx_runtime_1.jsx)(material_1.ListItemText, { primary: process.processType.name }), (0, jsx_runtime_1.jsx)(material_1.ListItemText, { secondary: process.status, primary: "\u043E\u0442 ".concat((0, helpers_1.getFormattedDate)(process.startDate, constants_1.DateFormats.ShortTextDateTime)) })] })), (0, jsx_runtime_1.jsx)(material_1.Divider, {})] })] }, process.id)); }) }) })) }));
};
exports.ProcessList = ProcessList;
