"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contractorOnSubscriptionTableFilters = void 0;
exports.contractorOnSubscriptionTableFilters = {
    contractorName: {
        id: 'contractorName',
        label: 'Контрагент',
        value: [],
    },
    contractorInn: {
        id: 'contractorInn',
        label: 'ИНН',
        value: '',
    },
    contractorOGRN: {
        id: 'contractorOGRN',
        label: 'ОГРН',
        value: '',
    },
    contractorKpp: {
        id: 'contractorKpp',
        label: 'КПП',
        value: '',
    },
    contractorTypes: {
        disabled: true,
        id: 'contractorTypes',
        label: 'Тип контрагента',
        value: '',
    },
};
