"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProfileInfoData = void 0;
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var getProfileInfoData = function (consumer) {
    var data = {
        icons: (consumer === null || consumer === void 0 ? void 0 : consumer.isConsumerTerrorist) ? [constants_1.IconInfoType.BlockedConsumer] : [],
        values: consumer
            ? [
                {
                    id: 'birthDate',
                    label: 'Дата рождения',
                    value: (0, helpers_1.getFormattedDate)(consumer.birthDate, constants_1.DateFormats.Date),
                },
            ]
            : [],
    };
    if (consumer === null || consumer === void 0 ? void 0 : consumer.realEstateNames.length) {
        data.values.push({
            id: '',
            label: "\u041E\u0431\u044A\u0435\u043A\u0442".concat(consumer.realEstateNames.length > 1 ? 'ы' : '', " \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u0438"),
            value: consumer.realEstateNames,
        });
    }
    return data;
};
exports.getProfileInfoData = getProfileInfoData;
