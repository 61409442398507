"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCourtCasesColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var date_filter_1 = require("../../../ud-ui/components/date-filter");
var SelectFilter_1 = require("../../../ud-ui/components/select-filter/SelectFilter");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var constants_2 = require("../constants");
var getCourtCasesColumns = function (props) {
    var caseDate = props.caseDate, roleParticipantTypes = props.roleParticipantTypes, onSubmit = props.onSubmit;
    return [
        {
            field: 'courtCaseNumber',
            filterable: false,
            flex: 200,
            headerName: '№ дела',
            minWidth: 180,
            sortable: false,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { id: "courtCaseNumber", name: "\u2116 \u0434\u0435\u043B\u0430", onSubmit: onSubmit('courtCaseNumber') }); },
        },
        {
            field: 'fixedAmountSum',
            filterable: false,
            flex: 200,
            headerName: 'Сумма требований',
            minWidth: 180,
            sortable: false,
            valueFormatter: function (params) { return (0, helpers_1.getFormattedNumber)(params.value); },
        },
        {
            field: 'requirementKindName',
            filterable: false,
            flex: 200,
            headerName: 'Вид требования',
            minWidth: 180,
            sortable: false,
        },
        {
            field: 'roleParticipant',
            filterable: false,
            flex: 200,
            headerName: 'Роль',
            minWidth: 140,
            sortable: false,
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(SelectFilter_1.SelectFilter, { id: "roleParticipant", name: "\u0420\u043E\u043B\u044C", options: constants_2.roleParticipantTypeOptions, values: roleParticipantTypes, onChange: onSubmit('roleParticipant') })); },
        },
        {
            field: 'hearingResult',
            filterable: false,
            flex: 300,
            headerName: 'Итог судебного заседания',
            minWidth: 200,
            sortable: false,
        },
        {
            field: 'decisionTakenInFavor',
            filterable: false,
            flex: 300,
            headerName: 'Принято решение в сторону',
            minWidth: 220,
            sortable: false,
            renderCell: function (params) { return params === null || params === void 0 ? void 0 : params.value; },
        },
        {
            field: 'resolutionPart',
            filterable: false,
            flex: 300,
            headerName: 'Резолютивная часть',
            minWidth: 220,
            sortable: false,
        },
        {
            field: 'caseDate',
            filterable: false,
            flex: 200,
            headerName: 'Дата начала',
            minWidth: 180,
            sortable: false,
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(date_filter_1.DateFilter, { fieldValue: caseDate, id: "caseDate", name: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430", onSubmit: onSubmit('caseDate') })); },
            valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.Date); },
        },
        {
            field: 'closingDate',
            filterable: false,
            flex: 200,
            headerName: 'Дата окончания',
            minWidth: 140,
            sortable: false,
            valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.Date); },
        },
    ];
};
exports.getCourtCasesColumns = getCourtCasesColumns;
