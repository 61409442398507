"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockedContractorPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var helpers_1 = require("../../../../../helpers");
var routes_1 = require("../../../../../navigation/routes");
var contractor_1 = require("../../../../contractor");
var get_contractor_info_1 = require("../../../../contractor/domain/helpers/get-contractor-info");
var go_back_container_1 = require("../../../../ud-ui/components/go-back-container");
var profile_info_1 = require("../../../../ud-ui/components/profile-info");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var permissions_1 = require("../../../../ud-ui/helpers/permissions");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var getBlockedContractorColumns_1 = require("../../../domain/helpers/getBlockedContractorColumns");
var reportsApi_1 = require("../../../store/api/reportsApi");
var BlockedContractorPage = function () {
    var _a, _b, _c, _d;
    var contractorId = (0, react_router_dom_1.useParams)().id;
    var canContractorRead = (0, permissions_1.hasPermissionInStorage)('contractorRead');
    /* TODO: usePagination */
    var _e = (0, react_1.useState)((0, helpers_1.getDefaultPagination)()), pageState = _e[0], setPageState = _e[1];
    var contractor = (0, contractor_1.useFetchContractorQuery)(contractorId);
    var blockedContractor = (0, reportsApi_1.useFetchBlockedMonitoringEventsQuery)(__assign(__assign({}, (0, helpers_1.getPaginationParams)(pageState)), { contractorId: contractorId }), { skip: !contractorId });
    var contractorInfo = (0, react_1.useMemo)(function () { return (0, get_contractor_info_1.getContractorProfileInfoData)(contractor.data); }, [contractor.data]);
    return ((0, jsx_runtime_1.jsxs)(page_container_1.PageContainer, __assign({ subTitle: "\u0417\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u0439 \u043A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442", leftElement: canContractorRead ? (0, jsx_runtime_1.jsx)(go_back_container_1.GoBackContainer, { goBackPath: routes_1.routes.reportBlockedContractors }) : undefined }, { children: [(0, jsx_runtime_1.jsx)(profile_info_1.ProfileInfo, __assign({}, contractorInfo, { name: (_a = contractor.data) === null || _a === void 0 ? void 0 : _a.name })), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, pageState, { columns: (0, getBlockedContractorColumns_1.getBlockedContractorColumns)(), isLoading: blockedContractor.isFetching, rows: (_b = blockedContractor.data) !== null && _b !== void 0 ? _b : [], rowCount: (_d = (_c = blockedContractor.data) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0, getRowId: function (_a) {
                    var monitoringEventId = _a.monitoringEventId;
                    return monitoringEventId;
                }, onPageModeChange: setPageState }))] })));
};
exports.BlockedContractorPage = BlockedContractorPage;
