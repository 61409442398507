"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = exports.blockBorderRadius = void 0;
var styles_1 = require("@mui/material/styles");
var x_data_grid_1 = require("@mui/x-data-grid");
// #fc4c02 orange
var shape = {
    borderRadius: 24,
    borderRadiusSmall: 16,
};
exports.blockBorderRadius = 5;
var palette = {
    primary: {
        dark: '#46A6F7',
        main: '#4285f4',
        light: '#46A6F7',
        contrastText: '#ffffff',
    },
    secondary: {
        dark: '#d4d4d4',
        main: '#ebebeb',
        light: '#f7f7f7',
        contrastText: '#494c62',
    },
    background: {
        default: '#fff',
    },
    borderColor: '#dcdfe0',
};
exports.theme = (0, styles_1.createTheme)({
    palette: palette,
    shape: shape,
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    fontSize: '14px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: shape.borderRadiusSmall,
                    textTransform: 'initial',
                    fontWeight: 600,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                sizeSmall: {
                    padding: '8px 16px',
                    lineHeight: '16px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    transform: 'translate(16px, 11px) scale(1)',
                },
                shrink: {
                    transform: 'translate(14px, -9px) scale(0.75)',
                },
                sizeSmall: {
                    transform: 'translate(15px, 6px) scale(1)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    lineHeight: '24px',
                    color: 'rgba(27, 31, 59, 0.8)',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: '12px 16px',
                    boxSizing: 'border-box',
                    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
                    borderRadius: shape.borderRadius,
                    fontSize: '1rem',
                },
                notchedOutline: {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                sizeSmall: {
                    padding: '7px 8px 7px 14px',
                    fontSize: '0.875rem',
                    borderRadius: shape.borderRadiusSmall,
                },
                input: {
                    padding: 0,
                    color: palette.secondary.contrastText,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.25)',
                    borderRadius: shape.borderRadiusSmall,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    boxSizing: 'border-box',
                    padding: 0,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 600,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: exports.blockBorderRadius,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    marginTop: 1,
                    marginBottom: 1,
                    whiteSpace: 'normal',
                },
            },
        },
    },
}, x_data_grid_1.ruRU);
