"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEW_REMARK_SETTINGS = void 0;
exports.NEW_REMARK_SETTINGS = [
    {
        id: 'courtCaseNumber',
        isTechnical: false,
        title: 'Исковое заявление',
    },
    {
        id: 'courtCaseId',
        isTechnical: true,
        title: 'СИД Искового заявления',
    },
    {
        id: 'forensicExaminationReportNumber',
        isTechnical: false,
        title: 'Экспертиза с этапом "Исковое заявление" №',
    },
    {
        id: 'forensicExaminationReportId',
        isTechnical: true,
        title: 'СИД Экспертизы с этапом "Исковое заявление"',
    },
    {
        id: 'statementOfClaimReportNumber',
        isTechnical: false,
        title: 'Экспертиза с этапом "Судебная экспертиза" №',
    },
    {
        id: 'statementOfClaimReportId',
        isTechnical: true,
        title: 'СИД Экспертизы с этапом "Судебная экспертиза"',
    },
    {
        id: 'statementOfClaimReportIssues',
        isTechnical: false,
        title: 'Количество замечаний в экспертизе с этапом "Судебная экспертиза"',
    },
];
