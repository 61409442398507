"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RiskIndicators = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var getRiskIndicatorsColumns_1 = require("../../../domain/helpers/getRiskIndicatorsColumns");
var RiskIndicators = function (props) {
    var _a, _b, _c;
    var expert = props.expert;
    return ((0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, { hideFooter: true, columns: (0, getRiskIndicatorsColumns_1.getRiskIndicatorsColumns)(), rowCount: (_b = (_a = expert.riskIndicators) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0, rows: (_c = expert.riskIndicators) !== null && _c !== void 0 ? _c : [] }));
};
exports.RiskIndicators = RiskIndicators;
