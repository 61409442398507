"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlockedContractorColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var getBlockedContractorColumns = function () {
    return [
        {
            field: 'riskFactorName',
            headerName: 'Блокирующий риск-фактор',
            flex: 400,
            filterable: false,
            maxWidth: 1000,
            minWidth: 200,
            sortable: false,
            renderCell: function (params) { return ((0, jsx_runtime_1.jsx)("span", __assign({ style: { wordWrap: 'break-word', whiteSpace: 'break-spaces' } }, { children: params.row.riskFactorName }))); },
        },
        {
            field: 'value',
            filterable: false,
            flex: 200,
            headerName: 'Значение',
            sortable: false,
        },
        {
            field: 'period',
            filterable: false,
            headerName: 'Период',
            sortable: false,
            width: 200,
            valueFormatter: function (params) {
                return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.ShortTextDateTime);
            },
        },
    ];
};
exports.getBlockedContractorColumns = getBlockedContractorColumns;
