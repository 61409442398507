"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitoringEventVerdictPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var routes_1 = require("../../../../../navigation/routes");
var store_1 = require("../../../../../store");
var processes_1 = require("../../../../processes");
var actions_1 = require("../../../../snackbar/store/actions");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var verdict_approvers_1 = require("../../components/verdict-approvers");
var S = __importStar(require("./style"));
var MonitoringEventVerdictPage = function () {
    var history = (0, react_router_dom_1.useHistory)();
    var dispatch = (0, store_1.useAppDispatch)();
    var eventId = (0, react_router_dom_1.useParams)().id;
    var processTypes = (0, processes_1.useFetchProcessTypesQuery)();
    var _a = (0, processes_1.useCreateWorkingProcessMutation)(), createWorkingProcess = _a[0], verdictResult = _a[1];
    var processVerdict = (0, react_1.useMemo)(function () { var _a; return (_a = processTypes.data) === null || _a === void 0 ? void 0 : _a.find(function (v) { return v.code === constants_1.ProcessTypeCode.Verdict; }); }, [processTypes.data]);
    var _b = (0, react_hook_form_1.useForm)({
        mode: 'onChange',
    }), control = _b.control, handleSubmit = _b.handleSubmit;
    (0, react_1.useEffect)(function () {
        if (verdictResult.isSuccess) {
            dispatch((0, actions_1.showSnackbar)({
                snackbarType: 'info',
                snackbarMessage: constants_1.alertMessages.successProcess,
            }));
            history.push((0, react_router_dom_1.generatePath)(routes_1.routes.monitoringEventForm, { id: eventId }));
        }
    }, [verdictResult.isSuccess]);
    var handleFormSubmit = function (values) {
        if (!processVerdict) {
            return;
        }
        var stages = Object.entries(values.stages).map(function (_a) {
            var processStageId = _a[0], approvers = _a[1];
            return ({
                processStageId: processStageId,
                approvers: approvers.map(function (_a) {
                    var id = _a.id;
                    return id;
                }),
            });
        });
        if (stages.some(function (v) { return !v.approvers.length; })) {
            dispatch((0, actions_1.showSnackbar)({
                snackbarType: 'warning',
                snackbarMessage: constants_1.alertMessages.noApprovers,
            }));
            return;
        }
        var body = {
            stages: stages,
            monitoringEventId: eventId,
            processTypeId: processVerdict.id,
            startDate: (0, helpers_1.getIsoDate)(new Date()),
        };
        createWorkingProcess(body);
    };
    return ((0, jsx_runtime_1.jsx)(page_container_1.PageContainer, __assign({ subTitle: "\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0430 \u00AB\u0412\u044B\u043D\u0435\u0441\u0435\u043D\u0438\u0435 \u0432\u0435\u0440\u0434\u0438\u043A\u0442\u0430\u00BB" }, { children: (0, jsx_runtime_1.jsx)("form", __assign({ onSubmit: handleSubmit(handleFormSubmit) }, { children: (0, jsx_runtime_1.jsxs)(material_1.Grid, __assign({ container: true, spacing: 2 }, { children: [(0, jsx_runtime_1.jsx)(material_1.Grid, __assign({ item: true, xs: 10 }, { children: processVerdict === null || processVerdict === void 0 ? void 0 : processVerdict.stages.map(function (stage) {
                            var _a;
                            return ((0, jsx_runtime_1.jsxs)(S.Container, { children: [(0, jsx_runtime_1.jsxs)(S.Title, { children: [stage.step, ". ", stage.name, ":"] }), (0, jsx_runtime_1.jsx)(verdict_approvers_1.VerdictApprovers, { control: control, defaultValues: (_a = stage.approverGroup) === null || _a === void 0 ? void 0 : _a.items, id: stage.id })] }, stage.id));
                        }) })), (0, jsx_runtime_1.jsx)(material_1.Grid, __assign({ container: true, item: true, alignItems: "flex-start", justifyContent: "flex-end", xs: 2 }, { children: (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ type: "submit", variant: "outlined" }, { children: "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C" })) }))] })) })) })));
};
exports.MonitoringEventVerdictPage = MonitoringEventVerdictPage;
