"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRolePermissionsColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var getRolePermissionsColumns = function (props) {
    var onDelete = props.onDelete;
    return [
        {
            field: 'description',
            filterable: false,
            flex: 1000,
            minWidth: 200,
            headerName: 'Роль',
            sortable: false,
        },
        {
            editable: false,
            field: '',
            filterable: false,
            headerName: '',
            sortable: false,
            width: 240,
            renderCell: function (params) { return ((0, jsx_runtime_1.jsx)(material_1.Button, __assign({ color: "error", "data-id": "deleteButton", onClick: function () { return onDelete(params.row); } }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }))); },
        },
    ];
};
exports.getRolePermissionsColumns = getRolePermissionsColumns;
