"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditRolePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var constants_1 = require("../../../../../constants");
var useSnackbar_1 = require("../../../../../hooks/useSnackbar");
var rolesApi_1 = require("../../../store/api/rolesApi");
var role_form_1 = require("../../components/role-form");
var EditRolePage = function (_a) {
    var id = _a.id, role = _a.role;
    var _b = (0, rolesApi_1.useUpdateRoleMutation)(), updateRole = _b[0], results = _b[1];
    (0, useSnackbar_1.useSuccessAlert)(results, constants_1.alertMessages.changeRoleSuccess);
    var onSubmit = (0, react_1.useCallback)(function (values) {
        updateRole({ id: id, body: values });
    }, [id]);
    return (0, jsx_runtime_1.jsx)(role_form_1.RoleForm, { isDisabled: results.isLoading, role: role, onSubmit: onSubmit });
};
exports.EditRolePage = EditRolePage;
