"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateRiskFactorForm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var constants_1 = require("../../../../../constants");
var useSnackbar_1 = require("../../../../../hooks/useSnackbar");
var routes_1 = require("../../../../../navigation/routes");
var go_back_container_1 = require("../../../../ud-ui/components/go-back-container");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var riskFactorApi_1 = require("../../../store/api/riskFactorApi");
var risk_factor_form_1 = require("../../components/risk-factor-form");
var CreateRiskFactorForm = function () {
    var _a = (0, riskFactorApi_1.useCreateRiskFactorMutation)(), createRiskFactor = _a[0], results = _a[1];
    (0, useSnackbar_1.useSuccessAlert)(results, constants_1.alertMessages.addRiskFactorSuccess, routes_1.routes.riskFactorList);
    var handleSubmit = (0, react_1.useCallback)(function (body) {
        createRiskFactor(body);
    }, []);
    return ((0, jsx_runtime_1.jsx)(page_container_1.PageContainer, __assign({ subTitle: "\u0420\u0438\u0441\u043A-\u0444\u0430\u043A\u0442\u043E\u0440 (\u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435)", leftElement: (0, jsx_runtime_1.jsx)(go_back_container_1.GoBackContainer, { goBackPath: routes_1.routes.riskFactorList }) }, { children: (0, jsx_runtime_1.jsx)(risk_factor_form_1.RiskFactorForm, { onSubmit: handleSubmit }) })));
};
exports.CreateRiskFactorForm = CreateRiskFactorForm;
