"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormattedNumber = void 0;
var getFormattedNumber = function (value) {
    var values = [];
    if (typeof value === 'number') {
        values = value.toString().split('.');
    }
    if (typeof value === 'string') {
        values = value.replace(',', '.').split('.');
        if (isNaN(Number(values[0]))) {
            return value;
        }
    }
    return values.map(function (v) { return v.replace(/\B(?=(\d{3})+(?!\d))/g, ' '); }).join('.');
};
exports.getFormattedNumber = getFormattedNumber;
