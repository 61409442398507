"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePagination = void 0;
var React = __importStar(require("react"));
var react_1 = require("react");
var constants_1 = require("../constants");
var helpers_1 = require("../helpers");
function usePagination(locationKey) {
    var _a = React.useState((0, helpers_1.getSavedPagination)(locationKey)), pagination = _a[0], setPagination = _a[1];
    (0, react_1.useEffect)(function () {
        (0, helpers_1.saveSessionData)(pagination.page, locationKey, constants_1.PERSISTENT_KEYS.page);
        (0, helpers_1.savePersistentData)(pagination.pageSize, constants_1.LOCATION_KEYS.all, constants_1.PERSISTENT_KEYS.pageSize);
    }, [pagination.page, pagination.pageSize]);
    var setPageParams = React.useCallback(function (values) {
        setPagination(function (state) {
            if (state.pageSize !== values.pageSize) {
                return {
                    page: constants_1.DEFAULT_PAGINATION.page,
                    pageSize: values.pageSize,
                };
            }
            return values;
        });
    }, []);
    var resetPage = React.useCallback(function () {
        setPagination(function (state) { return (__assign(__assign({}, state), { page: constants_1.DEFAULT_PAGINATION.page })); });
    }, []);
    return {
        pagination: pagination,
        resetPage: resetPage,
        setPageParams: setPageParams,
    };
}
exports.usePagination = usePagination;
