"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportRoutes = void 0;
var blocked_contractors_page_1 = require("../../ui/pages/blocked-contractors-page");
var consumer_terrorists_page_1 = require("../../ui/pages/consumer-terrorists-page");
var dishonest_expert_organizations_page_1 = require("../../ui/pages/dishonest-expert-organizations-page");
var unscrupulous_experts_page_1 = require("../../ui/pages/unscrupulous-experts-page");
__exportStar(require("./blocked-contractors"), exports);
__exportStar(require("./dishonest-expert-organizations"), exports);
__exportStar(require("./unscrupulous-experts"), exports);
exports.reportRoutes = [
    {
        Component: blocked_contractors_page_1.BlockedContractorsPage,
        label: 'Заблокированные контрагенты по риск-факторам',
        permission: 'contractorRead',
        tabId: 'blocked-contractors',
    },
    {
        Component: unscrupulous_experts_page_1.UnscrupulousExpertsPage,
        label: 'Недобросовестные эксперты',
        permission: 'expertRead',
        tabId: 'unscrupulous-experts',
    },
    {
        Component: dishonest_expert_organizations_page_1.DishonestExpertOrganizationsPage,
        label: 'Недобросовестные экспертные организации',
        permission: 'contractorRead',
        tabId: 'dishonest-expert-organizations',
    },
    {
        Component: consumer_terrorists_page_1.ConsumerTerroristsPage,
        label: 'Потребители-террористы',
        permission: 'contractorRead',
        tabId: 'consumer-terrorists',
    },
];
