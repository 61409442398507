"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractorIconsInfo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var icon_info_1 = require("../icon-info");
var sx = { marginLeft: 0.5 };
var ContractorIconsInfo = function (_a) {
    var contractor = _a.contractor;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!contractor.ogrn && (0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { sx: sx, type: constants_1.IconInfoType.EmptyOgrn }), contractor.unscrupulousExpertOrganization && ((0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { dataId: "unscrupulousExpertOrganizationIcon", sx: sx, type: constants_1.IconInfoType.BlockedContractor })), contractor.isBlockedByRiskFactor && ((0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { dataId: "blockedByRiskFactorIcon", sx: sx, type: constants_1.IconInfoType.BlockedByRiskFactor })), contractor.isBlocked && (0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { dataId: "blockedIcon", sx: sx, type: constants_1.IconInfoType.BlockedExpert })] }));
};
exports.ContractorIconsInfo = ContractorIconsInfo;
