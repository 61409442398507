"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSuccessAlert = void 0;
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var actions_1 = require("../modules/snackbar/store/actions");
var store_1 = require("../store");
var useSuccessAlert = function (result, snackbarMessage, path) {
    var history = (0, react_router_dom_1.useHistory)();
    var dispatch = (0, store_1.useAppDispatch)();
    (0, react_1.useEffect)(function () {
        if (result.isSuccess) {
            dispatch((0, actions_1.showSnackbar)({
                snackbarMessage: snackbarMessage,
                snackbarType: 'info',
            }));
            if (path) {
                history.push(path);
            }
        }
    }, [result.isSuccess]);
};
exports.useSuccessAlert = useSuccessAlert;
