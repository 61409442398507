"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSelectors = void 0;
/* TODO: fix */
/* eslint-disable @typescript-eslint/no-explicit-any */
var reselect_1 = require("reselect");
function createSelectors(stateSelector) {
    var entitiesStateSelector = function (state) { return stateSelector(state); };
    var entitiesInSelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.entities; });
    var isLoadingInSelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.isLoading; });
    var errorSelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.error; });
    var currentEntitySelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.currentEntity; });
    var totalSelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.total; });
    var searchEntitiesSelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.searchEntities; });
    var isOverflowedSelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.isOverflowed; });
    var loadApproversInProcessSelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.approversInProcess; });
    var tasksInProcessSelector = (0, reselect_1.createSelector)(entitiesStateSelector, function (state) { return state.tasks; });
    return {
        entitiesStateSelector: entitiesStateSelector,
        entitiesInSelector: entitiesInSelector,
        isLoadingInSelector: isLoadingInSelector,
        totalSelector: totalSelector,
        currentEntitySelector: currentEntitySelector,
        errorSelector: errorSelector,
        searchEntitiesSelector: searchEntitiesSelector,
        isOverflowedSelector: isOverflowedSelector,
        loadApproversInProcessSelector: loadApproversInProcessSelector,
        tasksInProcessSelector: tasksInProcessSelector,
    };
}
exports.createSelectors = createSelectors;
