"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.approverGroupsTableFiltersValues = void 0;
exports.approverGroupsTableFiltersValues = {
    name: {
        id: 'name',
        label: 'Наименование',
        value: '',
    },
};
