"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalCss = void 0;
var react_1 = require("@emotion/react");
exports.GlobalCss = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  body {\n    margin: 0;\n    padding: 0;\n    min-height: 100vh;\n    font-family: Graphik LCG;\n    overflow-y: auto;\n  }\n\n  * {\n    box-sizing: border-box;\n  }\n\n  html {\n    height: 100%;\n  }\n\n  #root {\n    height: 100%;\n  }\n\n  .filtersLayout {\n    margin: 24px 0 16px;\n  }\n\n  .selectLayout {\n    display: flex;\n    align-items: center;\n    margin-bottom: 1.5%;\n    gap: 16px;\n  }\n\n  .loadingLayout {\n    padding: 32px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .MuiMenu-list {\n    display: flex;\n    flex-direction: column;\n  }\n  .MuiCollapse-vertical {\n    display: flex;\n    flex-direction: column;\n  }\n"], ["\n  body {\n    margin: 0;\n    padding: 0;\n    min-height: 100vh;\n    font-family: Graphik LCG;\n    overflow-y: auto;\n  }\n\n  * {\n    box-sizing: border-box;\n  }\n\n  html {\n    height: 100%;\n  }\n\n  #root {\n    height: 100%;\n  }\n\n  .filtersLayout {\n    margin: 24px 0 16px;\n  }\n\n  .selectLayout {\n    display: flex;\n    align-items: center;\n    margin-bottom: 1.5%;\n    gap: 16px;\n  }\n\n  .loadingLayout {\n    padding: 32px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .MuiMenu-list {\n    display: flex;\n    flex-direction: column;\n  }\n  .MuiCollapse-vertical {\n    display: flex;\n    flex-direction: column;\n  }\n"])));
var templateObject_1;
