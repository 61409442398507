"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateRolePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var useSnackbar_1 = require("../../../../../hooks/useSnackbar");
var routes_1 = require("../../../../../navigation/routes");
var go_back_container_1 = require("../../../../ud-ui/components/go-back-container");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var rolesApi_1 = require("../../../store/api/rolesApi");
var role_form_1 = require("../../components/role-form");
var CreateRolePage = function () {
    var history = (0, react_router_dom_1.useHistory)();
    var _a = (0, rolesApi_1.useCreateRoleMutation)(), createRole = _a[0], results = _a[1];
    (0, useSnackbar_1.useSuccessAlert)(results, constants_1.alertMessages.addRoleSuccess);
    (0, react_1.useEffect)(function () {
        if (results.isSuccess) {
            history.push((0, react_router_dom_1.generatePath)(routes_1.routes.editRoleProfile, { id: results.data }));
        }
    }, [results.isSuccess]);
    var onSubmit = (0, react_1.useCallback)(function (values) {
        createRole(values);
    }, []);
    return ((0, jsx_runtime_1.jsx)(page_container_1.PageContainer, __assign({ subTitle: "\u0420\u043E\u043B\u0438 (\u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435)", leftElement: (0, jsx_runtime_1.jsx)(go_back_container_1.GoBackContainer, { goBackPath: routes_1.routes.roles }) }, { children: (0, jsx_runtime_1.jsx)(role_form_1.RoleForm, { isDisabled: results.isLoading, onSubmit: onSubmit }) })));
};
exports.CreateRolePage = CreateRolePage;
