"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UDDataGrid = exports.DataGrid = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var x_data_grid_1 = require("@mui/x-data-grid");
var locales_1 = require("@mui/x-data-grid/locales");
var React = __importStar(require("react"));
var DataGridPagination_1 = require("./DataGridPagination");
var S = __importStar(require("./style"));
var localeText = __assign(__assign({}, locales_1.ruRU.components.MuiDataGrid.defaultProps.localeText), { noRowsLabel: 'Данные отсутствуют' });
var DataGrid = function (props) {
    var _a = props.isLoading, isLoading = _a === void 0 ? false : _a, _b = props.page, page = _b === void 0 ? 1 : _b, _c = props.pageSize, pageSize = _c === void 0 ? 10 : _c, _d = props.getRowId, getRowId = _d === void 0 ? function (row) { return row.id; } : _d, onPageModeChange = props.onPageModeChange, _e = props.paginationMode, paginationMode = _e === void 0 ? 'server' : _e, gridProps = __rest(props, ["isLoading", "page", "pageSize", "getRowId", "onPageModeChange", "paginationMode"]);
    var handlePaginationModeChange = React.useCallback(function (_a) {
        var page = _a.page, pageSize = _a.pageSize;
        onPageModeChange === null || onPageModeChange === void 0 ? void 0 : onPageModeChange({ page: page, pageSize: pageSize });
    }, [onPageModeChange]);
    var extProps = React.useMemo(function () { return ({
        paginationModel: { pageSize: pageSize, page: page },
        slots: {
            pagination: DataGridPagination_1.DataGridPagination,
        },
        sx: S.sxStyle,
        getRowHeight: function () { return 'auto'; },
    }); }, [pageSize, page, S.sxStyle]);
    return ((0, jsx_runtime_1.jsx)(x_data_grid_1.DataGrid, __assign({}, gridProps, extProps, { autoHeight: true, disableRowSelectionOnClick: true, getRowId: getRowId, loading: isLoading, localeText: localeText, paginationMode: paginationMode, onPaginationModelChange: handlePaginationModeChange })));
};
exports.DataGrid = DataGrid;
exports.UDDataGrid = React.memo(exports.DataGrid);
