"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConsumersColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var date_filter_1 = require("../../../ud-ui/components/date-filter");
var icon_info_1 = require("../../../ud-ui/components/icon-info");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var getConsumersColumns = function (props) {
    var birthDate = props.birthDate, onSubmit = props.onSubmit;
    return [
        {
            field: 'name',
            filterable: false,
            flex: 400,
            minWidth: 200,
            sortable: false,
            renderCell: function (params) { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [params.row.name, params.row.isBlocked && (0, jsx_runtime_1.jsx)(icon_info_1.IconInfo, { sx: { marginLeft: 1 }, type: constants_1.IconInfoType.BlockedConsumer })] })); },
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0424\u0418\u041E", onSubmit: onSubmit('name'), id: "name" }); },
        },
        {
            field: 'birthDate',
            filterable: false,
            flex: 400,
            minWidth: 200,
            sortable: false,
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(date_filter_1.DateFilter, { fieldValue: birthDate, id: "birthDate", name: "\u0414\u0430\u0442\u0430 \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F", onSubmit: onSubmit('birthDate') })); },
            valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.Date); },
        },
    ];
};
exports.getConsumersColumns = getConsumersColumns;
