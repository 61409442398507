"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMappedData = void 0;
var helpers_1 = require("../../../../helpers");
var constants_1 = require("../constants");
var getMappedData = function (settings, data, isTechnical) {
    var mappedData = {
        rows: [],
        titles: [],
        subTitles: [],
    };
    settings.forEach(function (setting) {
        if (setting.isTechnical && !isTechnical) {
            return;
        }
        mappedData.titles.push(setting.title);
        mappedData.subTitles.push(setting.isTechnical ? constants_1.TECHNICAL_TITLE : '');
    });
    data.forEach(function (values) {
        var cols = [];
        settings.forEach(function (setting) {
            var _a;
            if (setting.isTechnical && !isTechnical) {
                return;
            }
            var value = (_a = values[setting.id]) !== null && _a !== void 0 ? _a : '';
            if (setting.isAmount) {
                value = (0, helpers_1.getFormattedNumber)(value);
            }
            cols.push(value);
        });
        mappedData.rows.push(cols);
    });
    return mappedData;
};
exports.getMappedData = getMappedData;
