"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monitoringEventsTableFilters = void 0;
exports.monitoringEventsTableFilters = {
    contractorName: {
        id: 'contractorName',
        label: 'Контрагент',
        value: [],
    },
    contractorInn: {
        id: 'contractorInn',
        label: 'ИНН',
        value: '',
    },
    riskFactorName: {
        id: 'riskFactorName',
        label: 'Риск-фактор',
        value: [],
    },
};
