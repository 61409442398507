"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEntitiesStore = void 0;
var create_actions_1 = require("./create-actions");
var create_selectors_1 = require("./create-selectors");
var create_slice_1 = require("./create-slice");
function createEntitiesStore(name, options) {
    var actions = (0, create_actions_1.createEntitiesActions)(name);
    var slice = (0, create_slice_1.createEntitiesSlice)(actions, name);
    var selectors = (0, create_selectors_1.createSelectors)(options.stateSelector);
    var reducer = slice.reducer;
    return {
        reducer: reducer,
        actions: actions,
        slice: slice,
        selectors: selectors,
    };
}
exports.createEntitiesStore = createEntitiesStore;
