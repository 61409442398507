"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OVERESTIMATING_DEFECTS_SETTINGS = void 0;
exports.OVERESTIMATING_DEFECTS_SETTINGS = [
    {
        id: 'forensicExaminationReportNumber',
        isTechnical: false,
        title: 'Экспертиза №',
    },
    {
        id: 'forensicExaminationReportId',
        isTechnical: true,
        title: 'СИД Экспертизы',
    },
    {
        id: 'forensicExaminationReportCost',
        isAmount: true,
        isTechnical: false,
        title: 'Сумма Экспертизы',
    },
    {
        id: 'courtCaseNumber',
        isTechnical: false,
        title: 'Исковое заявление',
    },
    {
        id: 'courtCaseId',
        isTechnical: true,
        title: 'СИД Искового заявления',
    },
    {
        id: 'mainDebtClaimSum',
        isAmount: true,
        isTechnical: false,
        title: 'Сумма искового заявления с видом платежа "Основной долг"',
    },
    {
        id: 'legalCourtHearingId',
        isTechnical: true,
        title: 'СИД Судебного заседания',
    },
    {
        id: 'legalCourtHearingSum',
        isAmount: true,
        isTechnical: false,
        title: 'Сумма судебного заседания с видом платежа "Основной долг"',
    },
];
