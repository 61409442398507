"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPopoverSettings = exports.getDictionaryLabels = void 0;
/**
 * Возвращает текстовые значения фильтра с мульти-выбором
 * @param value - значения, которые являются ключами в словаре, через запятую
 * @param dictionary - словарь значений
 */
var getDictionaryLabels = function (value, dictionary) {
    var values = Array.isArray(value) ? value : [];
    if (typeof value === 'string') {
        values = value.split(',');
    }
    return values
        .map(function (v) { return (typeof v === 'string' ? dictionary[v] : null); })
        .filter(Boolean)
        .join(', ');
};
exports.getDictionaryLabels = getDictionaryLabels;
/**
 * Возвращаются настройки всплывающего окна с фильтрами для данных в таблице
 * @param id - для поиска узла всплывающего окна
 */
var getPopoverSettings = function (id) {
    return { variant: 'popover', popupId: "".concat(id, "Popover") };
};
exports.getPopoverSettings = getPopoverSettings;
