"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractorsOnSubColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var helpers_1 = require("../../../../helpers");
var contractor_1 = require("../../../contractor");
var contractor_icons_info_1 = require("../../../ud-ui/components/contractor-icons-info");
var select_filter_1 = require("../../../ud-ui/components/select-filter");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var getContractorsOnSubColumns = function (props) {
    var contractorTypes = props.contractorTypes, onSubmit = props.onSubmit;
    return [
        {
            field: 'period',
            filterable: false,
            headerName: 'Период',
            sortingOrder: ['desc', 'asc'],
            type: 'date',
            width: 140,
            valueFormatter: function (params) { return (0, helpers_1.getFormattedDate)(params.value); },
        },
        {
            field: 'contractor.name',
            filterable: false,
            flex: 400,
            minWidth: 200,
            sortable: false,
            renderCell: function (params) { return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [params.row.contractor.name, (0, jsx_runtime_1.jsx)(contractor_icons_info_1.ContractorIconsInfo, { contractor: params.row.contractor })] })); },
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435", onSubmit: onSubmit('contractorName'), id: "contractorName" }); },
        },
        {
            field: 'contractor.type',
            filterable: false,
            sortable: false,
            width: 200,
            renderCell: function (params) { return (0, contractor_1.getContractorTypeName)(params.row.contractorType); },
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(select_filter_1.SelectFilter, { id: "contractorTypes", name: "\u0422\u0438\u043F \u043A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442\u0430", options: contractor_1.contractorTypeOptions, values: contractorTypes, onChange: onSubmit('contractorTypes') })); },
        },
        {
            field: 'contractor.ogrn',
            filterable: false,
            sortable: false,
            width: 160,
            renderCell: function (params) { return params.row.contractor.ogrn; },
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041E\u0413\u0420\u041D", onSubmit: onSubmit('contractorOGRN'), id: "contractorOGRN" }); },
        },
        {
            field: 'contractor.inn',
            filterable: false,
            sortable: false,
            width: 130,
            renderCell: function (params) { return params.row.contractor.inn; },
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0418\u041D\u041D", onSubmit: onSubmit('contractorInn'), id: "contractorInn" }); },
        },
        {
            field: 'contractor.kpp',
            filterable: false,
            headerName: 'КПП',
            sortable: false,
            width: 130,
            renderCell: function (params) { return params.row.contractor.kpp; },
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041A\u041F\u041F", onSubmit: onSubmit('contractorKpp'), id: "contractorKpp" }); },
        },
        {
            field: 'isSubscribed',
            filterable: false,
            headerName: 'Наблюдается',
            sortable: false,
            width: 130,
            renderCell: function (params) { return (params.row.isSubscribed ? 'Да' : 'Нет'); },
        },
    ];
};
exports.getContractorsOnSubColumns = getContractorsOnSubColumns;
