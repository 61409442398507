"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routes = void 0;
exports.routes = {
    accessDenied: '/access-denied',
    approvers: '/approvers/:id(create|edit|list)/:approverGroupId?',
    approversGroupList: '/approvers/list',
    approversGroupCreate: '/approvers/create',
    approversGroupEdit: '/approvers/edit/:approverGroupId',
    contractorOnSub: '/contractor-on-subscription',
    contractor: '/contractor/:id/:tabId(court-cases|experts|risk-factors|risk-indicators)',
    contractorCard: '/contractor/:id/risk-factors',
    contractorCourtCases: '/contractor/:id/court-cases',
    /* consumers */
    consumers: '/consumers',
    consumerList: '/consumers/list',
    consumer: '/consumers/:id',
    /* expert */
    experts: '/experts/:id?/:tabId(contractor|risk-indicators)?',
    expertList: '/experts/list',
    expert: '/experts/:id/:tabId(contractor|risk-indicators)',
    expertContractor: '/experts/:id/contractor',
    expertRiskIndicators: '/experts/:id/risk-indicators',
    login: '/login',
    loginOidCallback: '/login/gate',
    mdmMessages: '/mdm-messages',
    /* monitoringEvents */
    monitoringEvents: '/',
    monitoringEvent: '/monitoring-event/:id/:tabId(history|verdict)?',
    monitoringEventForm: '/monitoring-event/:id',
    monitoringEventVerdict: '/monitoring-event/:id/verdict',
    monitoringEventHistory: '/monitoring-event/:id/history',
    reports: '/reports/:tabId(blocked-contractors|unscrupulous-experts|dishonest-expert-organizations|consumer-terrorists)',
    reportBlockedContractors: '/reports/blocked-contractors',
    reportBlockedContractor: '/reports/blocked-contractor/:id',
    /* riskFactors */
    riskFactors: '/risk-factors/:id(create|edit|list)?/:riskFactorId?',
    riskFactorList: '/risk-factors/list',
    editRiskFactor: '/risk-factors/edit/:riskFactorId',
    createRiskFactor: '/risk-factors/create',
    /* riskIndicator */
    riskIndicator: '/risk-indicator/:scoringResultType(contractor|expert)/:typeId(expertise-cost|negative-expert|new-remark|overestimating-defects|unconfirmed-defects|consumer-terrorist)/:scoringResultId',
    /* roles */
    roles: '/roles/:id(create|edit|list)?/:roleId?/:tabId(profile|permissions)?',
    createRole: '/roles/create',
    editRole: '/roles/edit/:roleId/:tabId(profile|permissions)',
    editRoleProfile: '/roles/edit/:id/profile',
    editRolePermissions: '/roles/edit/:id/permissions',
    roleList: '/roles/list',
    /* users */
    users: '/users/:id(create|edit|list)?/:userId?/:tabId(profile|roles)?',
    createUser: '/users/create',
    editUser: '/users/edit/:userId/:tabId(profile|roles)',
    editUserProfile: '/users/edit/:userId/profile',
    editUserRoles: '/users/edit/:userId/roles',
    userList: '/users/list',
};
