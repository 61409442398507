"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContractorProfileInfoData = void 0;
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var constants_2 = require("../constants");
var contractorProperties = [
    { label: 'Статус', id: 'status' },
    { label: 'Тип контрагента', id: 'types', type: 'dictionary' },
    { label: 'Адрес', id: 'businessAddress' },
    { label: 'ИНН', id: 'inn' },
    { label: 'ОГРН', id: 'ogrn' },
    { label: 'КПП', id: 'kpp' },
    { label: 'Учредитель', id: 'founder' },
    { label: 'Руководитель', id: 'head' },
    { label: 'Дата образования', id: 'registrationDate', type: 'date' },
    { label: 'Дата прекращения деятельности', id: 'dissolutionDate', type: 'date' },
];
/**
 * Возвращает отформатированные данные профиля контрагента
 * @param values - данные, которые приходят с сервера
 */
var getContractorProfileInfoData = function (values) {
    var data = {
        icons: [],
        values: [],
    };
    if (!values) {
        return data;
    }
    if (values.isBlocked) {
        data.icons.push(constants_1.IconInfoType.BlockedExpert);
    }
    if (values.isBlockedByRiskFactor) {
        data.icons.push(constants_1.IconInfoType.BlockedByRiskFactor);
    }
    if (values.unscrupulousExpertOrganization) {
        data.icons.push(constants_1.IconInfoType.BlockedContractor);
    }
    contractorProperties.forEach(function (_a) {
        var id = _a.id, label = _a.label, type = _a.type;
        var value;
        var rawValue = values[id];
        if (typeof rawValue === 'string') {
            if (!type) {
                value = rawValue;
            }
            if (type === 'date') {
                value = (0, helpers_1.getDate)(rawValue, constants_1.DateFormats.Iso, constants_1.DateFormats.Date);
            }
        }
        if (type === 'dictionary') {
            value = (0, helpers_1.getDictionaryLabels)(rawValue, constants_2.contractorTypeDictionary);
        }
        if (value) {
            data.values.push({
                id: id,
                label: label,
                value: value,
            });
        }
    });
    return data;
};
exports.getContractorProfileInfoData = getContractorProfileInfoData;
