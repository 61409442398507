"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApproverSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_hook_form_1 = require("react-hook-form");
var constants_1 = require("../../../../../constants");
var useApproversSelect_1 = require("../../../../../hooks/useApproversSelect");
var ApproverSelect = function (_a) {
    var control = _a.control;
    var approversSelectProps = (0, useApproversSelect_1.useApproversSelect)();
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { control: control, name: "approverOptions", defaultValue: constants_1.EMPTY_ARR, render: function (_a) {
            var field = _a.field;
            return ((0, jsx_runtime_1.jsx)(material_1.Autocomplete, __assign({}, approversSelectProps, field, { multiple: true, disableClearable: true, "data-id": "autocompleteApproverOptions", getOptionLabel: function (option) { return option.name; }, renderInput: function (params) { return (0, jsx_runtime_1.jsx)(material_1.TextField, __assign({}, params, { placeholder: constants_1.TEXTS.enterValues })); }, onChange: function (event, item) { return field.onChange(item); } })));
        } }));
};
exports.ApproverSelect = ApproverSelect;
