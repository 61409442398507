"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRoleAdditionForm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var constants_1 = require("../../../../../constants");
var useSnackbar_1 = require("../../../../../hooks/useSnackbar");
var rolesApi_1 = require("../../../../roles/store/api/rolesApi");
var usersApi_1 = require("../../../store/api/usersApi");
var UserRoleAdditionForm = function (_a) {
    var id = _a.id, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, userRoles = _a.userRoles;
    var roles = (0, rolesApi_1.useFetchRolesQuery)('');
    var _c = (0, usersApi_1.useAddUserRoleMutation)(), addUserRole = _c[0], addUserRoleResults = _c[1];
    (0, useSnackbar_1.useSuccessAlert)(addUserRoleResults, constants_1.alertMessages.addRoleSuccess);
    var roleOptions = (0, react_1.useMemo)(function () {
        var _a, _b;
        if (userRoles && roles.data) {
            var names_1 = new Set(userRoles === null || userRoles === void 0 ? void 0 : userRoles.map(function (v) { return v.id; }));
            return roles.data.rows.filter(function (v) { return !names_1.has(v.id); });
        }
        return (_b = (_a = roles.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [];
    }, [userRoles, roles.data]);
    var handleAddRoleSubmit = function (values) {
        addUserRole(__assign(__assign({}, values), { id: id }));
        reset({}, { keepDefaultValues: true });
    };
    var _d = (0, react_hook_form_1.useForm)({
        defaultValues: {
            rolesIds: [],
        },
    }), control = _d.control, handleSubmit = _d.handleSubmit, isDirty = _d.formState.isDirty, reset = _d.reset;
    return ((0, jsx_runtime_1.jsx)("form", __assign({ onSubmit: handleSubmit(handleAddRoleSubmit) }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "selectLayout" }, { children: [(0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { name: "rolesIds", control: control, render: function (_a) {
                        var field = _a.field;
                        return ((0, jsx_runtime_1.jsx)(material_1.Select, __assign({}, field, { multiple: true, disabled: isDisabled || !(roleOptions === null || roleOptions === void 0 ? void 0 : roleOptions.length), placeholder: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0440\u043E\u043B\u044C", size: "small", sx: { width: 400 } }, { children: roleOptions === null || roleOptions === void 0 ? void 0 : roleOptions.map(function (option) { return ((0, jsx_runtime_1.jsx)(material_1.MenuItem, __assign({ value: option.id }, { children: option.description }), option.id)); }) })));
                    } }), (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ color: "primary", "data-id": "addUserRoleButton", disabled: !isDirty || !roleOptions.length, size: "small", type: "submit", variant: "contained" }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" }))] })) })));
};
exports.UserRoleAdditionForm = UserRoleAdditionForm;
