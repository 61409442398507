"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractorRiskIndicatorsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var helpers_1 = require("../../../../../helpers");
var risk_indicator_name_1 = require("../../../../ud-ui/components/risk-indicator-name");
var table_container_1 = require("../../../../ud-ui/components/table-container");
var ContractorRiskIndicatorsPage = function (_a) {
    var _b;
    var contractor = _a.contractor;
    return ((0, jsx_runtime_1.jsx)(table_container_1.TableContainer, { children: (0, jsx_runtime_1.jsxs)(material_1.Table, { children: [(0, jsx_runtime_1.jsx)(material_1.TableHead, { children: (0, jsx_runtime_1.jsxs)(material_1.TableRow, { children: [(0, jsx_runtime_1.jsx)(material_1.TableCell, { children: "\u0420\u0438\u0441\u043A-\u0438\u043D\u0434\u0438\u043A\u0430\u0442\u043E\u0440" }), (0, jsx_runtime_1.jsx)(material_1.TableCell, { children: "\u0414\u0430\u0442\u0430 \u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u043A\u0438" })] }) }), (0, jsx_runtime_1.jsx)(material_1.TableBody, { children: ((_b = contractor.riskIndicators) === null || _b === void 0 ? void 0 : _b.length) ? (contractor.riskIndicators.map(function (expert) { return ((0, jsx_runtime_1.jsxs)(material_1.TableRow, { children: [(0, jsx_runtime_1.jsx)(material_1.TableCell, __assign({ sx: { fontWeight: 500, fontSize: '1rem' } }, { children: (0, jsx_runtime_1.jsx)(risk_indicator_name_1.RiskIndicatorName, { name: expert.name, scoringResultId: expert.scoringResultId, scoringResultType: "contractor" }) })), (0, jsx_runtime_1.jsx)(material_1.TableCell, { children: (0, helpers_1.getFormattedDate)(expert.date) })] }, expert.id)); })) : ((0, jsx_runtime_1.jsx)(material_1.TableRow, { children: (0, jsx_runtime_1.jsx)(material_1.TableCell, __assign({ colSpan: 2, sx: { padding: 5 }, align: "center" }, { children: "\u041F\u0440\u0438\u0437\u043D\u0430\u043A\u0438 \u043D\u0435\u0434\u043E\u0431\u0440\u043E\u0441\u043E\u0432\u0435\u0441\u0442\u043D\u043E\u0441\u0442\u0438 \u044D\u043A\u0441\u043F\u0435\u0440\u0442\u043D\u043E\u0439 \u043E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u0438 \u043E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u044E\u0442" })) })) })] }) }));
};
exports.ContractorRiskIndicatorsPage = ContractorRiskIndicatorsPage;
