"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchCourtCasesQuery = exports.useFetchContractorQuery = exports.contractorApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
exports.contractorApi = (0, react_1.createApi)({
    reducerPath: 'contractors',
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ['Contractor', 'CourtCases'],
    endpoints: function (builder) { return ({
        fetchContractor: builder.query({
            query: function (id) { return ({
                url: "/api/v1/contractor/".concat(id),
            }); },
            providesTags: ['Contractor'],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchCourtCases: builder.query({
            query: function (_a) {
                var id = _a.id, params = _a.params;
                return ({
                    url: "/api/v1/contractor/".concat(id, "/courtcases").concat(params),
                });
            },
            providesTags: ['CourtCases'],
            transformResponse: function (result) { return ({
                rows: result.payload.map(function (record, index) { return (__assign(__assign({}, record), { id: index.toString() })); }),
                total: result.meta.total,
            }); },
        }),
    }); },
});
exports.useFetchContractorQuery = exports.contractorApi.useFetchContractorQuery, exports.useFetchCourtCasesQuery = exports.contractorApi.useFetchCourtCasesQuery;
