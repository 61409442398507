"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApproverGroupBody = void 0;
var getApproverGroupBody = function (values) {
    var _a, _b;
    return (__assign(__assign({}, values), { approversIds: (_b = (_a = values.approverOptions) === null || _a === void 0 ? void 0 : _a.map(function (v) { return v.id; })) !== null && _b !== void 0 ? _b : [] }));
};
exports.getApproverGroupBody = getApproverGroupBody;
