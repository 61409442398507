"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnscrupulousExpertColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var risk_indicator_name_1 = require("../../../ud-ui/components/risk-indicator-name");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var getUnscrupulousExpertColumns = function (props) {
    var onSubmit = props.onSubmit;
    return [
        {
            field: 'name',
            filterable: false,
            flex: 300,
            minWidth: 240,
            sortable: false,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0424\u0418\u041E", onSubmit: onSubmit('name'), id: "name" }); },
        },
        {
            field: 'riskIndicatorName',
            filterable: false,
            flex: 400,
            minWidth: 200,
            sortable: false,
            renderCell: function (params) { return ((0, jsx_runtime_1.jsx)(risk_indicator_name_1.RiskIndicatorName, { name: params.value, scoringResultId: params.row.scoringResultId, scoringResultType: "expert" })); },
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0420\u0438\u0441\u043A-\u0418\u043D\u0434\u0438\u043A\u0430\u0442\u043E\u0440", onSubmit: onSubmit('riskIndicatorName'), id: "riskIndicatorName" })); },
        },
        {
            field: 'period',
            filterable: false,
            flex: 200,
            headerName: 'Период',
            minWidth: 140,
            sortable: false,
            valueFormatter: function (params) {
                return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.ShortTextDateTime);
            },
        },
    ];
};
exports.getUnscrupulousExpertColumns = getUnscrupulousExpertColumns;
