"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var actions_1 = require("../../../store/login/actions");
var selectors_1 = require("../../../store/login/selectors");
var S = __importStar(require("./style"));
var PikLoginAuthPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var errorMessage = (0, react_redux_1.useSelector)(selectors_1.errorAuthMessageSelector);
    var onLoginPressed = (0, react_1.useCallback)(function () {
        dispatch((0, actions_1.login)());
    }, []);
    return ((0, jsx_runtime_1.jsx)(S.Container, __assign({ container: true, spacing: 0, direction: "column", alignItems: "center", justifyContent: "center" }, { children: (0, jsx_runtime_1.jsxs)(S.LoginForm, { children: [(0, jsx_runtime_1.jsx)(S.LoginTitle, { children: "\u041C\u043E\u043D\u0438\u0442\u043E\u0440\u0438\u043D\u0433 \u0441\u043E\u0441\u0442\u043E\u044F\u043D\u0438\u044F \u043A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442\u043E\u0432" }), (0, jsx_runtime_1.jsx)(S.LoginButton, __assign({ "data-id": "loginButton", variant: "contained", color: "primary", onClick: onLoginPressed }, { children: "\u0412\u043E\u0439\u0442\u0438 \u0441 \u0443\u0447\u0435\u0442\u043D\u043E\u0439 \u0437\u0430\u043F\u0438\u0441\u044C\u044E \u041F\u0418\u041A" })), errorMessage && (0, jsx_runtime_1.jsx)("p", { children: errorMessage })] }) })));
};
exports.default = PikLoginAuthPage;
