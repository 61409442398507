"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXPERTISE_COST_SETTINGS = void 0;
exports.EXPERTISE_COST_SETTINGS = [
    {
        id: 'negotiationCost',
        isTechnical: false,
        title: 'Стоимость экспертизы по переговорам',
    },
    {
        id: 'negotiationId',
        isTechnical: true,
        title: 'СИД Переговоров',
    },
    {
        id: 'paymentDocRequestSum',
        isAmount: true,
        isTechnical: false,
        title: 'Сумма СПД',
    },
    {
        id: 'paymentDocRequestId',
        isTechnical: true,
        title: 'СИД СПД',
    },
];
