"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RiskIndicatorName = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../navigation/routes");
var constants_1 = require("../../../risk-indicator/domain/constants");
var RiskIndicatorName = function (_a) {
    var name = _a.name, scoringResultId = _a.scoringResultId, scoringResultType = _a.scoringResultType;
    var history = (0, react_router_dom_1.useHistory)();
    var handleClick = function () {
        var typeId = constants_1.riskIndicatorMap[name];
        history.push((0, react_router_dom_1.generatePath)(routes_1.routes.riskIndicator, {
            scoringResultId: scoringResultId,
            scoringResultType: scoringResultType,
            typeId: typeId,
        }));
    };
    return (0, jsx_runtime_1.jsx)("div", __assign({ onDoubleClick: handleClick }, { children: name }));
};
exports.RiskIndicatorName = RiskIndicatorName;
