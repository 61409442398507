"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dishonestExpertOrganizationsFilters = void 0;
exports.dishonestExpertOrganizationsFilters = {
    name: {
        id: 'name',
        label: 'Контрагент',
        value: '',
    },
    inn: {
        id: 'inn',
        label: 'ИНН',
        value: '',
    },
    period: {
        id: 'period',
        label: '',
        value: '',
    },
    riskIndicatorName: {
        id: 'riskIndicatorName',
        label: 'Риск-Индикатор',
        value: '',
    },
};
