"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateApproverGroupPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var routes_1 = require("../../../../../navigation/routes");
var store_1 = require("../../../../../store");
var processes_1 = require("../../../../processes");
var actions_1 = require("../../../../snackbar/store/actions");
var go_back_container_1 = require("../../../../ud-ui/components/go-back-container");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var getApproverGroupBody_1 = require("../../../domain/helpers/getApproverGroupBody");
var approversApi_1 = require("../../../store/api/approversApi");
var approver_group_form_1 = require("../../components/approver-group-form");
var CreateApproverGroupPage = function () {
    var _a = (0, approversApi_1.useCreateApproverGroupMutation)(), createApproverGroup = _a[0], results = _a[1];
    var history = (0, react_router_dom_1.useHistory)();
    var dispatch = (0, store_1.useAppDispatch)();
    (0, react_1.useEffect)(function () {
        if (results.isSuccess) {
            dispatch(processes_1.processTypesApi.util.invalidateTags(['ProcessTypes']));
            dispatch((0, actions_1.showSnackbar)({
                snackbarMessage: constants_1.alertMessages.addApproverGroupSuccess,
                snackbarType: 'info',
            }));
            history.push(routes_1.routes.approversGroupList);
        }
    }, [results.isSuccess]);
    var handleSubmit = (0, react_1.useCallback)(function (values) {
        createApproverGroup((0, getApproverGroupBody_1.getApproverGroupBody)(values));
    }, []);
    return ((0, jsx_runtime_1.jsx)(page_container_1.PageContainer, __assign({ subTitle: "\u0421\u043F\u0438\u0441\u043E\u043A \u0441\u043E\u0433\u043B\u0430\u0441\u0443\u044E\u0449\u0438\u0445 (\u0441\u043E\u0437\u0434\u0430\u043D\u0438\u0435)", leftElement: (0, jsx_runtime_1.jsx)(go_back_container_1.GoBackContainer, { goBackPath: routes_1.routes.approversGroupList }) }, { children: (0, jsx_runtime_1.jsx)(approver_group_form_1.ApproverGroupForm, { isDisabled: results.isLoading, onSubmit: handleSubmit }) })));
};
exports.CreateApproverGroupPage = CreateApproverGroupPage;
