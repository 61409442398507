"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockedContractorsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var useContractorTypes_1 = require("../../../../../hooks/useContractorTypes");
var useTableFilters_1 = require("../../../../../hooks/useTableFilters");
var routes_1 = require("../../../../../navigation/routes");
var contractor_1 = require("../../../../contractor");
var filter_values_1 = require("../../../../ud-ui/components/filter-values");
var form_calendar_1 = require("../../../../ud-ui/components/form-calendar");
var select_1 = require("../../../../ud-ui/components/select");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var constants_2 = require("../../../domain/constants");
var getBlockedContractorsColumns_1 = require("../../../domain/helpers/getBlockedContractorsColumns");
var reportsApi_1 = require("../../../store/api/reportsApi");
var blocked_contractors_export_excel_1 = require("../../components/blocked-contractors-export-excel");
var recalculate_blocked_contractors_1 = require("../../components/recalculate-blocked-contractors");
var S = __importStar(require("./style"));
var defaultFilters = {
    period: '',
};
var BlockedContractorsPage = function () {
    var history = (0, react_router_dom_1.useHistory)();
    var _a = (0, useTableFilters_1.useTableFilters)(constants_1.LOCATION_KEYS.blockedContractors, constants_2.blockedContractorsFilters, defaultFilters), filters = _a.filters, pagination = _a.pagination, tableFilters = _a.tableFilters, changeFilter = _a.changeFilter, changeTableFilter = _a.changeTableFilter, deleteTableFilter = _a.deleteTableFilter, getTableFiltersParams = _a.getTableFiltersParams, setTableFilter = _a.setTableFilter, setPageParams = _a.setPageParams;
    var _b = (0, useContractorTypes_1.useContractorTypes)(constants_1.LOCATION_KEYS.blockedContractors), contractorTypes = _b.contractorTypes, handelContractorTypesChange = _b.handelContractorTypesChange;
    var queryParams = __assign(__assign(__assign({}, getTableFiltersParams()), (0, helpers_1.getPaginationParams)(pagination)), { period: (0, helpers_1.getIsoEndDate)(filters.period), contractorTypes: contractorTypes });
    var blockedContractors = (0, reportsApi_1.useFetchBlockedContractorsQuery)((0, helpers_1.getQueryParams)(queryParams));
    var dataGridProps = React.useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            columns: (0, getBlockedContractorsColumns_1.getBlockedContractorsColumns)({
                onSubmit: setTableFilter,
            }),
            rows: (_b = (_a = blockedContractors.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [],
            rowCount: (_d = (_c = blockedContractors.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0,
        });
    }, [blockedContractors.data]);
    var handleCellDoubleClicked = React.useCallback(function (_a) {
        var row = _a.row;
        history.push((0, react_router_dom_1.generatePath)(routes_1.routes.reportBlockedContractor, { id: row.contractorId }));
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(S.FiltersLayout, __assign({ "data-id": "filterBlockedContractors" }, { children: [(0, jsx_runtime_1.jsxs)(S.FiltersWrapBlock, { children: [(0, jsx_runtime_1.jsx)(form_calendar_1.FormCalendar, { isClearable: true, dataId: "period", fieldTitle: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043F\u0435\u0440\u0438\u043E\u0434", fieldValue: filters.period, setFieldValue: changeFilter('period') }), (0, jsx_runtime_1.jsx)(select_1.Select, { multiple: true, label: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0442\u0438\u043F \u043A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442\u0430", options: contractor_1.contractorTypeOptions, value: contractorTypes, onChange: handelContractorTypesChange })] }), (0, jsx_runtime_1.jsxs)(S.FiltersBlock, { children: [(0, jsx_runtime_1.jsx)(blocked_contractors_export_excel_1.BlockedContractorsExportExcelButton, { params: queryParams }), (0, jsx_runtime_1.jsx)(recalculate_blocked_contractors_1.RecalculateBlockedContractors, {})] })] })), (0, jsx_runtime_1.jsx)(filter_values_1.FilterValues, { values: tableFilters, onChange: changeTableFilter, onDelete: deleteTableFilter }), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, dataGridProps, pagination, { isLoading: blockedContractors.isFetching, onCellDoubleClick: handleCellDoubleClicked, onPageModeChange: setPageParams }))] }));
};
exports.BlockedContractorsPage = BlockedContractorsPage;
