"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTableFilters = void 0;
var React = __importStar(require("react"));
var react_1 = require("react");
var constants_1 = require("../constants");
var helpers_1 = require("../helpers");
var usePagination_1 = require("./usePagination");
function useTableFilters(locationKey, defaultTableFilters, defaultFilters) {
    if (defaultFilters === void 0) { defaultFilters = null; }
    var pagination = (0, usePagination_1.usePagination)(locationKey);
    // фильтры таблиц, применяемые в заголовках
    var _a = React.useState(function () {
        return (0, helpers_1.getSavedTableFilters)(locationKey, defaultTableFilters);
    }), tableFilters = _a[0], setTableFilters = _a[1];
    var _b = React.useState((0, helpers_1.getSavedFilters)(locationKey, defaultFilters)), filters = _b[0], setFilters = _b[1];
    (0, react_1.useEffect)(function () {
        (0, helpers_1.saveSessionData)(tableFilters, locationKey, constants_1.PERSISTENT_KEYS.tableFilters);
        (0, helpers_1.saveSessionData)(filters, locationKey, constants_1.PERSISTENT_KEYS.filters);
    }, [tableFilters, filters]);
    var changeFilter = React.useCallback(function (field) { return function (value) {
        setFilters(function (state) {
            var _a;
            return (__assign(__assign({}, state), (_a = {}, _a[field] = value, _a)));
        });
        pagination.resetPage();
    }; }, []);
    var setTableFilter = React.useCallback(function (field) { return function (value) {
        setTableFilters(function (state) {
            var _a, _b;
            var currentFilter = state[field];
            if (Array.isArray(currentFilter.value)) {
                return !value || currentFilter.value.includes(value)
                    ? state
                    : __assign(__assign({}, state), (_a = {}, _a[field] = __assign(__assign({}, state[field]), { value: currentFilter.value.concat(value) }), _a));
            }
            return __assign(__assign({}, state), (_b = {}, _b[field] = __assign(__assign({}, state[field]), { value: value }), _b));
        });
        pagination.resetPage();
    }; }, []);
    var deleteTableFilter = React.useCallback(function (field, value) {
        setTableFilters(function (state) {
            var _a, _b;
            var currentFilter = state[field];
            if (!Array.isArray(currentFilter.value)) {
                return __assign(__assign({}, state), (_a = {}, _a[field] = __assign(__assign({}, state[field]), { value: '' }), _a));
            }
            // если фильтр массив значений, удаляем только переданное
            return !value
                ? state
                : __assign(__assign({}, state), (_b = {}, _b[field] = __assign(__assign({}, state[field]), { value: currentFilter.value.filter(function (v) { return v !== value; }) }), _b));
        });
        pagination.resetPage();
    }, []);
    var changeTableFilter = React.useCallback(function (field, values) {
        setTableFilters(function (state) {
            var _a, _b;
            var currentFilter = state[field];
            if (currentFilter.disabled) {
                return state;
            }
            if (Array.isArray(currentFilter.value)) {
                return currentFilter.value.includes(values.newValue)
                    ? state
                    : __assign(__assign({}, state), (_a = {}, _a[field] = __assign(__assign({}, state[field]), { value: currentFilter.value.map(function (v) { return (v === values.oldValue ? values.newValue : v); }) }), _a));
            }
            else {
                return currentFilter.value === values.newValue
                    ? state
                    : __assign(__assign({}, state), (_b = {}, _b[field] = __assign(__assign({}, state[field]), { value: values.newValue }), _b));
            }
        });
        pagination.resetPage();
    }, []);
    var getTableFiltersParams = function () {
        var activeFilters = {};
        Object.values(tableFilters).forEach(function (v) {
            if (Array.isArray(v.value) && v.value.length) {
                activeFilters[v.id] = v.value;
            }
            if (typeof v.value === 'string' && v.value) {
                activeFilters[v.id] = v.value;
            }
        });
        return activeFilters;
    };
    return __assign(__assign({}, pagination), { filters: filters, tableFilters: tableFilters, changeFilter: changeFilter, changeTableFilter: changeTableFilter, deleteTableFilter: deleteTableFilter, getTableFiltersParams: getTableFiltersParams, setTableFilter: setTableFilter });
}
exports.useTableFilters = useTableFilters;
