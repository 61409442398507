"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var actions_1 = require("../store/actions");
var S = __importStar(require("./style"));
var AlertMessage = function (props) {
    var open = props.open, message = props.message, severity = props.severity;
    var dispatch = (0, react_redux_1.useDispatch)();
    var closeHandle = (0, react_1.useCallback)(function () {
        dispatch((0, actions_1.dismissSnackBar)());
    }, []);
    return ((0, jsx_runtime_1.jsx)(S.Container, __assign({ open: open, autoHideDuration: 3000, onClose: closeHandle, anchorOrigin: { vertical: 'top', horizontal: 'right' } }, { children: (0, jsx_runtime_1.jsx)(material_1.Alert, __assign({ icon: false, elevation: 6, variant: "filled", severity: severity, onClose: closeHandle }, { children: message })) })));
};
exports.default = AlertMessage;
