"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDishonestExpertOrganizationColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../../constants");
var helpers_1 = require("../../../../helpers");
var risk_indicator_name_1 = require("../../../ud-ui/components/risk-indicator-name");
var text_filter_1 = require("../../../ud-ui/components/text-filter");
var getDishonestExpertOrganizationColumns = function (props) {
    var onSubmit = props.onSubmit;
    return [
        {
            field: 'name',
            flex: 300,
            filterable: false,
            minWidth: 200,
            sortable: false,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u041A\u043E\u043D\u0442\u0440\u0430\u0433\u0435\u043D\u0442", onSubmit: onSubmit('name'), id: "name" }); },
        },
        {
            field: 'inn',
            width: 190,
            filterable: false,
            sortable: false,
            renderHeader: function () { return (0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0418\u041D\u041D", onSubmit: onSubmit('inn'), id: "inn" }); },
        },
        {
            field: 'riskIndicatorName',
            flex: 300,
            filterable: false,
            sortable: false,
            renderHeader: function () { return ((0, jsx_runtime_1.jsx)(text_filter_1.TextFilter, { name: "\u0420\u0438\u0441\u043A-\u0418\u043D\u0434\u0438\u043A\u0430\u0442\u043E\u0440", onSubmit: onSubmit('riskIndicatorName'), id: "riskIndicatorName" })); },
            renderCell: function (params) { return ((0, jsx_runtime_1.jsx)(risk_indicator_name_1.RiskIndicatorName, { name: params.value, scoringResultId: params.row.scoringResultId, scoringResultType: "contractor" })); },
        },
        {
            field: 'period',
            filterable: false,
            flex: 200,
            headerName: 'Дата выявления',
            minWidth: 140,
            sortable: false,
            valueFormatter: function (params) {
                return (0, helpers_1.getFormattedDate)(params.value, constants_1.DateFormats.ShortTextDateTime);
            },
        },
    ];
};
exports.getDishonestExpertOrganizationColumns = getDishonestExpertOrganizationColumns;
