"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RolePermissionsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var constants_1 = require("../../../../../constants");
var usePagination_1 = require("../../../../../hooks/usePagination");
var store_1 = require("../../../../../store");
var actions_1 = require("../../../../snackbar/store/actions");
var alert_dialog_1 = require("../../../../ud-ui/components/alert-dialog");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var getRolePermissionsColumns_1 = require("../../../domain/helpers/getRolePermissionsColumns");
var rolesApi_1 = require("../../../store/api/rolesApi");
var role_permissions_addition_form_1 = require("../../components/role-permissions-addition-form");
var RolePermissionsPage = function (_a) {
    var id = _a.id;
    var dispatch = (0, store_1.useAppDispatch)();
    var pagination = (0, usePagination_1.usePagination)(constants_1.LOCATION_KEYS.rolePermissions);
    var _b = (0, react_1.useState)(null), deletedRolePermission = _b[0], setDeletedRolePermission = _b[1];
    var userRolePermissions = (0, rolesApi_1.useFetchUserRolePermissionsQuery)(id);
    var _c = (0, rolesApi_1.useDeleteRolePermissionMutation)(), deleteRolePermissions = _c[0], deleteRoleResults = _c[1];
    (0, react_1.useEffect)(function () {
        if (deleteRoleResults.isSuccess) {
            dispatch((0, actions_1.showSnackbar)({
                snackbarType: 'info',
                snackbarMessage: constants_1.alertMessages.deletePermissionSuccess,
            }));
        }
    }, [deleteRoleResults.isSuccess]);
    var handleRolePermissionDeleteClick = (0, react_1.useCallback)(function (role) {
        setDeletedRolePermission(role);
    }, []);
    var handleRolePermissionDeleteCancel = (0, react_1.useCallback)(function () {
        setDeletedRolePermission(null);
    }, []);
    var handleRolePermissionDeleteSubmit = (0, react_1.useCallback)(function () {
        if (deletedRolePermission) {
            deleteRolePermissions({
                permissionsIds: deletedRolePermission.id,
                id: id,
            });
            setDeletedRolePermission(null);
        }
    }, [deletedRolePermission]);
    var dataGridProps = react_1.default.useMemo(function () {
        var _a, _b, _c, _d;
        var _e = pagination.pagination, page = _e.page, pageSize = _e.pageSize;
        return {
            columns: (0, getRolePermissionsColumns_1.getRolePermissionsColumns)({
                onDelete: handleRolePermissionDeleteClick,
            }),
            page: page,
            pageSize: pageSize,
            rows: (_b = (_a = userRolePermissions.data) === null || _a === void 0 ? void 0 : _a.slice(page * pageSize, page * pageSize + pageSize)) !== null && _b !== void 0 ? _b : [],
            rowCount: (_d = (_c = userRolePermissions.data) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0,
            onPageModeChange: pagination.setPageParams,
        };
    }, [pagination, userRolePermissions.data]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(role_permissions_addition_form_1.RolePermissionsAdditionForm, { id: id, isDisabled: userRolePermissions.isFetching, userRolePermissions: userRolePermissions.data }), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, dataGridProps, { isLoading: userRolePermissions.isFetching })), deletedRolePermission && ((0, jsx_runtime_1.jsx)(alert_dialog_1.AlertDialog, { isOpen: true, description: "\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u0440\u043E\u043B\u044C \"".concat(deletedRolePermission.description, "\"? \u042D\u0442\u043E \u043F\u0440\u0438\u0432\u0435\u0434\u0435\u0442 \u043A \u0443\u0434\u0430\u043B\u0435\u043D\u0438\u044E \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u043D\u043E\u0433\u043E \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043D\u0438\u044F \u0443 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439 \u0441 \u044D\u0442\u043E\u0439 \u0440\u043E\u043B\u044C\u044E"), onCancel: handleRolePermissionDeleteCancel, onSubmit: handleRolePermissionDeleteSubmit }))] }));
};
exports.RolePermissionsPage = RolePermissionsPage;
