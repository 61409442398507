"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventCommentForm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var constants_1 = require("../../../../../constants");
var store_1 = require("../../../../../store");
var S = __importStar(require("../../../../../styles"));
var actions_1 = require("../../../../snackbar/store/actions");
var permissions_1 = require("../../../../ud-ui/helpers/permissions");
var monitoringEventsApi_1 = require("../../../store/api/monitoringEventsApi");
var EventCommentForm = function (_a) {
    var data = _a.data;
    var dispatch = (0, store_1.useAppDispatch)();
    var _b = (0, monitoringEventsApi_1.useUpdateMonitoringEventMutation)(), monitoringEvent = _b[0], results = _b[1];
    var canProcessWrite = (0, permissions_1.hasPermissionInStorage)('processWrite');
    var _c = (0, react_hook_form_1.useForm)({
        defaultValues: {
            comment: data.comment,
        },
    }), _d = _c.formState, isDirty = _d.isDirty, isValid = _d.isValid, handleSubmit = _c.handleSubmit, register = _c.register;
    (0, react_1.useEffect)(function () {
        if (results.isSuccess) {
            dispatch((0, actions_1.showSnackbar)({
                snackbarType: 'info',
                snackbarMessage: constants_1.alertMessages.changeEventCommentSuccess,
            }));
        }
    }, [results.isSuccess]);
    var onSubmit = (0, react_1.useCallback)(function (_a) {
        var comment = _a.comment;
        monitoringEvent({
            id: data.id,
            body: __assign(__assign({}, data), { comment: comment }),
        });
    }, [data]);
    return ((0, jsx_runtime_1.jsxs)("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [(0, jsx_runtime_1.jsxs)(S.VerticalFieldLayout, { children: [(0, jsx_runtime_1.jsx)(S.VerticalLabel, { children: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439:" }), (0, jsx_runtime_1.jsx)(material_1.TextField, __assign({ fullWidth: true, multiline: true }, register('comment'), { disabled: !canProcessWrite, rows: 4, variant: "outlined" }))] }), (0, jsx_runtime_1.jsx)(S.ActionsLayout, { children: (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ fullWidth: true, color: "primary", disabled: !canProcessWrite || !isDirty || !isValid, type: "submit", variant: "outlined" }, { children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" })) })] })));
};
exports.EventCommentForm = EventCommentForm;
