"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchExpertsQuery = exports.useFetchExpertQuery = exports.expertsApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery_1 = require("../../../../api/baseQuery");
exports.expertsApi = (0, react_1.createApi)({
    reducerPath: 'experts',
    baseQuery: baseQuery_1.baseQuery,
    tagTypes: ['Expert', 'Experts'],
    endpoints: function (builder) { return ({
        fetchExpert: builder.query({
            query: function (id) { return ({
                url: "/api/v1/expert/".concat(id),
            }); },
            providesTags: ['Expert'],
            transformResponse: function (result) { return result.payload; },
        }),
        fetchExperts: builder.query({
            query: function (params) { return ({
                url: "/api/v1/expert".concat(params),
            }); },
            providesTags: ['Experts'],
            transformResponse: function (result) { return ({
                rows: result.payload,
                total: result.meta.total,
            }); },
        }),
    }); },
});
exports.useFetchExpertQuery = exports.expertsApi.useFetchExpertQuery, exports.useFetchExpertsQuery = exports.expertsApi.useFetchExpertsQuery;
