"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditUserPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var constants_1 = require("../../../../../constants");
var useSnackbar_1 = require("../../../../../hooks/useSnackbar");
var usersApi_1 = require("../../../store/api/usersApi");
var user_form_1 = require("../../components/user-form");
var EditUserPage = function (_a) {
    var user = _a.user;
    var _b = (0, usersApi_1.useUpdateUserMutation)(), updateUser = _b[0], results = _b[1];
    (0, useSnackbar_1.useSuccessAlert)(results, constants_1.alertMessages.changeUserSuccess);
    var handleUserSubmit = (0, react_1.useCallback)(function (value) {
        updateUser({ id: user.id, body: value });
    }, [user]);
    return (0, jsx_runtime_1.jsx)(user_form_1.UserForm, { isDisabled: results.isLoading, user: user, onSubmit: handleUserSubmit });
};
exports.EditUserPage = EditUserPage;
