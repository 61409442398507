"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserListPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var react_router_1 = require("react-router");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var useSnackbar_1 = require("../../../../../hooks/useSnackbar");
var useTableFilters_1 = require("../../../../../hooks/useTableFilters");
var routes_1 = require("../../../../../navigation/routes");
var alert_dialog_1 = require("../../../../ud-ui/components/alert-dialog");
var filter_values_1 = require("../../../../ud-ui/components/filter-values");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var permissions_1 = require("../../../../ud-ui/helpers/permissions");
var page_container_1 = require("../../../../ud-ui/pages/page-container");
var constants_2 = require("../../../domain/constants");
var getUsersColumns_1 = require("../../../domain/helpers/getUsersColumns");
var usersApi_1 = require("../../../store/api/usersApi");
var UserListPage = function () {
    var history = (0, react_router_1.useHistory)();
    var _a = (0, react_1.useState)(null), deletedUser = _a[0], setDeletedUser = _a[1];
    var _b = (0, usersApi_1.useDeleteUserMutation)(), deleteUser = _b[0], deleteUserResults = _b[1];
    (0, useSnackbar_1.useSuccessAlert)(deleteUserResults, constants_1.alertMessages.deleteUserSuccess);
    var _c = (0, useTableFilters_1.useTableFilters)(constants_1.LOCATION_KEYS.userList, constants_2.userTableFiltersValues), tableFilters = _c.tableFilters, pagination = _c.pagination, changeTableFilter = _c.changeTableFilter, deleteTableFilter = _c.deleteTableFilter, getTableFiltersParams = _c.getTableFiltersParams, setTableFilter = _c.setTableFilter, setPageParams = _c.setPageParams;
    var users = (0, usersApi_1.useFetchUsersQuery)((0, helpers_1.getQueryParams)(__assign(__assign({}, getTableFiltersParams()), (0, helpers_1.getPaginationParams)(pagination))));
    var canCreating = (0, permissions_1.hasPermissionInStorage)('userWrite');
    var handleCreateUserClick = (0, react_1.useCallback)(function () {
        history.push(routes_1.routes.createUser);
    }, []);
    var handleDeleteClick = (0, react_1.useCallback)(function (user) {
        setDeletedUser(user);
    }, []);
    var handleUserDeleteSubmit = (0, react_1.useCallback)(function () {
        if (deletedUser) {
            deleteUser(deletedUser.id);
            setDeletedUser(null);
        }
    }, [deletedUser]);
    var handleUserDeleteCancel = (0, react_1.useCallback)(function () {
        setDeletedUser(null);
    }, []);
    var handleEditClick = (0, react_1.useCallback)(function (userId) {
        history.push((0, react_router_dom_1.generatePath)(routes_1.routes.editUserProfile, { userId: userId }), {
            pagination: pagination,
            tableFilters: tableFilters,
        });
    }, [pagination, tableFilters]);
    var dataGridProps = react_1.default.useMemo(function () {
        var _a, _b, _c, _d;
        return ({
            columns: (0, getUsersColumns_1.getUsersColumns)({
                onSubmit: setTableFilter,
                onDelete: handleDeleteClick,
                onEdit: handleEditClick,
            }),
            rows: (_b = (_a = users.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [],
            rowCount: (_d = (_c = users.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0,
        });
    }, [users.data]);
    return ((0, jsx_runtime_1.jsxs)(page_container_1.PageContainer, __assign({ subTitle: "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438", leftElement: (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ "data-id": "addUserButton", disabled: !canCreating, size: "small", variant: "outlined", onClick: handleCreateUserClick }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C" })) }, { children: [(0, jsx_runtime_1.jsx)(filter_values_1.FilterValues, { values: tableFilters, onDelete: deleteTableFilter, onChange: changeTableFilter }), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, dataGridProps, pagination, { isLoading: users.isFetching, onPageModeChange: setPageParams })), deletedUser && ((0, jsx_runtime_1.jsx)(alert_dialog_1.AlertDialog, { isOpen: true, description: "\u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F ".concat(deletedUser.name, "?"), onCancel: handleUserDeleteCancel, onSubmit: handleUserDeleteSubmit }))] })));
};
exports.UserListPage = UserListPage;
