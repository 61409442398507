"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoBackContainer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_router_dom_1 = require("react-router-dom");
var GoBackContainer = function (props) {
    var children = props.children, className = props.className, goBackPath = props.goBackPath, _a = props.title, title = _a === void 0 ? 'Выход' : _a;
    var history = (0, react_router_dom_1.useHistory)();
    var handleClick = function () {
        if (goBackPath) {
            history.push(goBackPath);
        }
        else {
            history.goBack();
        }
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Box, __assign({ className: className, "data-id": "goBackContainer", sx: {
            display: 'flex',
            justifyContent: !children ? 'flex-end' : 'space-between',
            alignItems: 'center',
            marginBottom: 2,
        } }, { children: [children, (0, jsx_runtime_1.jsx)(material_1.Button, __assign({ color: "inherit", "data-id": "goBackButton", variant: "outlined", onClick: handleClick }, { children: title }))] })));
};
exports.GoBackContainer = GoBackContainer;
